import {EVENTS_TPA_PAGE_ID} from './constants'

export const EVENT_MANAGE_EVENTS = 'manageEvents'
export const EVENT_DELETE_EVENTS = 'deleteEvents'

const handlers = {
  [EVENT_MANAGE_EVENTS]: async ({sdk, appToken}) => {
    await sdk.editor.openDashboardPanel(appToken, {url: 'events', closeOtherPanels: false})
    sdk.tpa.app.refreshApp()
  },
  [EVENT_DELETE_EVENTS]: async ({sdk, appToken}) => {
    // Deleting main page first causes confirmation popup to show up
    const eventsPages = await sdk.document.pages.getApplicationPages(appToken)
    const essentialPage = eventsPages.find((page: any) => page.tpaPageId === EVENTS_TPA_PAGE_ID)
    await sdk.document.pages.remove(appToken, {pageRef: {id: essentialPage.id}})

    // In theory app should already be deleted at this point but it is often not the case
    await sdk.document.tpa.app.delete(appToken)
  },
}

export const onEventFactory = (getAppToken: Function) => async ({eventType, eventPayload}, editorSDK) => {
  await handlers?.[eventType]?.({sdk: editorSDK, payload: eventPayload, appToken: getAppToken()})
}
